<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="form-center">
            <div class="wrapp-section-title section-title-center">
              <h2 class="section-title">Sign in</h2>
            </div>
            <form action="#!" method="post" class="login-form">
              <div class="form-field form-field-large">
                <label for="login-name" class="form-field-label">Email</label>
                <input
                  type="text"
                  class="form-field-input"
                  name="LoginName"
                  value
                  autocomplete="off"
                  required="required"
                  id="login-name"
                />
              </div>
              <div class="form-field form-field-large">
                <label for="login-password" class="form-field-label">Password</label>
                <input
                  type="password"
                  class="form-field-input"
                  name="loginPassword"
                  value
                  autocomplete="off"
                  required="required"
                  id="login-password"
                />
              </div>
              <div class="form-field">
                <div class="checkbox">
                  <input
                    type="checkbox"
                    class="checkbox-input"
                    name="checkboxLogin"
                    id="checkbox-login"
                  />
                  <label for="checkbox-login" class="checkbox-label">
                    <span class="checkbox-ico">
                      <svg viewBox="0 0 512 512">
                        <use xlink:href="img/sprite.svg#check" />
                      </svg>
                    </span>
                    Remember me
                  </label>
                </div>
              </div>
              <div class="form-btn form-btn-wide">
                <button type="submit" class="btn btn-large btn-w240 ripple">Sign in</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>